import React from 'react';
import styled from 'styled-components';

const gen_all_users_list = () => {

};

const StyledButton = styled.button`
  margin: 0.3rem 0.3rem;
  padding: 0.3rem 0.5rem;
  color: #d952be;
  background: black;
  cursor: pointer;
  line-height: 1.7rem;
  border: solid 1px white;
  font-size: inherit;
  font-family: inherit;
`;

const WhiteText = styled.span`
  color: white;
`;

const ButtonBox = styled.div`
  margin: 0rem 0rem 2rem 0rem;
  // position: fixed;
  // width: 100%;
  // bottom: 0;
`;

const MobileButton = props => {

  const clickHandle = gameName => {
    props.onClick(gameName)
  }

  return (
    <ButtonBox>
      <p>click for more details on our projects:</p>
      <StyledButton onClick={() => clickHandle('ld kite')}><WhiteText>ld</WhiteText> kite </StyledButton>
      <StyledButton onClick={() => clickHandle('ld shi_ra')}><WhiteText>ld</WhiteText> shi_ra </StyledButton>
      <StyledButton onClick={() => clickHandle('ld ananas')}><WhiteText>ld</WhiteText> ananas </StyledButton>
      <StyledButton onClick={() => clickHandle('ld gesusas_shape_garden')}><WhiteText>ld</WhiteText> gesusas_shape_garden </StyledButton>
      <StyledButton onClick={() => clickHandle('ld jumpnbump')}><WhiteText>ld</WhiteText> jumpnbump </StyledButton>
      <StyledButton onClick={() => clickHandle('ld station_ao8')}><WhiteText>ld</WhiteText> station_ao8</StyledButton>
      <StyledButton onClick={() => clickHandle('ld about')}><WhiteText>ld</WhiteText> about the studio</StyledButton>
    </ButtonBox>
  );
};

export default MobileButton;
