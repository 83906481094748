import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: #779180;
`;

export const ShapeGarden = props => {
	return <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>GESUSA'S SHAPE GARDEN</p></GameHeader>
			<p>An analog-digital game instalation where you build shapes from gesusa's garden.</p>
			<ImageCarousel width="333" height="333" images={['/images/shapeGarden/shapeGarden4.jpg', '/images/shapeGarden/shapeGarden1.jpg', '/images/shapeGarden/shapeGarden2.jpg', '/images/shapeGarden/shapeGarden11.jpg', '/images/shapeGarden/shapeGarden5.jpg', '/images/shapeGarden/shapeGarden12.jpg', '/images/shapeGarden/shapeGarden13.jpg', '/images/shapeGarden/shapeGarden7.jpg', '/images/shapeGarden/shapeGarden8.jpg', '/images/shapeGarden/shapeGarden9.jpg', '/images/shapeGarden/shapeGarden10.jpg', '/images/shapeGarden/shapeGarden6.jpg', '/images/shapeGarden/shapeGarden3.jpg',]}></ImageCarousel>
			<p>The installation combines the digital and analog by placing multiple cardboard boxes alongside an original gameboy arcade machine. In the gameboy game you can be gesusa (a legendary hero from <a href="https://colorful-courier.itch.io/gesusa-hilfe">gesusa hilfe</a>) and wander a garden with shapes. Then, you are invited to build those shapes out of cardboard boxes in real life.</p>
			<p>▞ credits:<br></br>
				<a href="http://o--0oo0--o.com/">aharon manor</a> / game design, music ,art, code<br></br>
				<a href="http://woodentonguedepressor.com/">sasha lee</a> / design, electronics, code<br></br>
				<a href="https://idankimel.org/">idan kimel</a> / code<br></br>
				premiered at <a href="https://hansen.co.il/event/%D7%A4%D7%A1%D7%98%D7%99%D7%91%D7%9C-play/">'play'</a> festival hansen house ,2019.<br></br>
			</p>
		</DelayedLoader>
	</GameDiv>
};