import { useState, useEffect } from 'react';
import styled from 'styled-components';

const NoMarginParagraph = styled.p`
  margin: 0;
  padding: 0;
`;

const LOAD_CHARS = ['|', '/', '-', '\\']

function Loading() {

  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (frame < LOAD_CHARS.length - 1) {
        setFrame(frame + 1);
      } else {
        setFrame(0);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [frame]);

  return (
    <NoMarginParagraph>Loading... {LOAD_CHARS[frame]}</NoMarginParagraph>
  );
}

export default Loading;
