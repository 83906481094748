import { useState, useEffect } from 'react';

const DelayedLoader = props => {
	const [phase, setPhase] = useState(0);

	const phases = props.children;

	const showingPhases = [];

	for (let i = 0; i <= phase; i++) {
		showingPhases.push(phases[i]);
	}

	useEffect(() => {
		if (phase < phases.length - 1) {
			props.onNewLoad();
	        const timer = setTimeout(() => {
	        	if (phase < phases.length - 2) {
	            	setPhase(phase + 1);
	            } else {
	            	setPhase(phase + 1);
	            	props.onLoadingDone();
	            }
	        }, 1000);

	        return () => clearTimeout(timer);
	    }
    }, [phase]);

	return <div>
		{ showingPhases }
	</div>;
};

export default DelayedLoader;