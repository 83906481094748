import { useState, useEffect } from 'react';

import styled from 'styled-components';

const IconSpan = styled.span`
	cursor: pointer;
	display: inline-block;
	font-size: 40px;
	margin-top: 1rem;
`;

const AlignedImage = styled.img`
	padding: 0 0px;
	padding-right:20px;
	vertical-align:middle;
`;

const ImageCarousel = props => {
	const [imageIndex, setImageIndex] = useState(0);
	const [isCycling, setIsCycling] = useState(true);

	const images = props.images;

	const moveToNext = () => setImageIndex((imageIndex + 1) % images.length);

	useEffect(() => {
		if (isCycling) {
	        const timer = setTimeout(() => {
	        	moveToNext();
	        }, 4000);

	        return () => clearTimeout(timer);
	    }
    }, [imageIndex, isCycling]);

	return <div>
		<AlignedImage src={images[imageIndex]} alt="image" width={props.width} height={props.height}></AlignedImage><IconSpan onClick={() => {setIsCycling(false); moveToNext();}}>▶</IconSpan><br></br>
	</div>;
};

export default ImageCarousel;