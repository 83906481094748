import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: magenta;
`;

export const Shi__ra = props => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>SHI__RA</p></GameHeader>

			<p>a spiritual arcade machine, incapsulating an original gameboy game.</p>
			<ImageCarousel width="333" height="479" images={['/images/shi__ra/machin_white.jpg','/images/shi__ra/title.JPG','/images/shi__ra/sasha_lightning.gif','/images/shi__ra/moonwalk.JPG','/images/shi__ra/ruth.JPG','/images/shi__ra/pallet.JPG','/images/shi__ra/oldmap.JPG','/images/shi__ra/wire.JPG','/images/shi__ra/coins.JPG','/images/shi__ra/idanSong.JPG']}></ImageCarousel>

			<p>With the help of a coin, you can wander around an 8-bit environment, witness different landscapes, and add a poem of your own in a place of your choice. Poems planted by previous players, are available for you to read, if you wish.</p>
			<p>The work makes use of the unique nature of the arcade machine, allowing to create digital-analog relationships. To take part in the occurrence, the player must be in a public space, in front of the machine and insert a coin. Only when these conditions are fulfilled is it possible to delve into the pastoral landscapes and read the words left for us.</p>
			<p>Like a Japanese Haiku, if you choose to write a poem you take part in the minimalist gameboy experience, where you will have to be precise with your words. A maximum of 16 characters in a row, 7 rows, and 48 characters for a poem.</p>
			<p>the Nintendo Gameboy was released in 1989 and till this day remains as an iconic piece of hardware. its simplicity of hardware design gave it unique meanings. 4 shades of gray, 8bit registers, 3 layers of graphics, 4 audio channels and cartridges with a tight space of 32kb. Because of its so limited environment, the gameboy allows access to approaches and forms of expression that have no place in contemporary hardware.</p>
			
			<p>▞ credits:<br></br>
				<a href="http://o--0oo0--o.com/">aharon manor</a> / game design, code, art, music<br></br>
				<a href="http://woodentonguedepressor.com/">sasha lee</a> / design, electronics, code, game design<br></br>
				<a href="https://idankimel.org/">idan kimel</a> / game design, code<br></br>
				premiered at <a href="https://www.jdw.co.il/en/" target="blank">jerusalem design week</a> 2019. 
			</p>
		</DelayedLoader>
	</GameDiv>;
};