import games from '../components/games/games';

const NO_SPACE_AFTER_LD_MESSAGE = 'No space after ld. To load a game type ld [GAME] [ENTER].';

const commandNotFoundMessage = command => `Command ${command} not found. Type help [ENTER] for a list of commands.`;
const gameNotFoundMessage = command => `Game ${command} not found. Type ls [ENTER] for a list of games.`;

export const parseCommand = (inputCommand, addCommand) => {
	let resultItems = [];

	const command = inputCommand.toLowerCase();

	if (addCommand || addCommand === undefined) {
		resultItems.push({type: 'command', text: command});
	}

	if (command === 'about') {
		resultItems.push({type: 'component', game: 'about'});
	} else if (command === 'ls') {
		resultItems.push({type: 'games-list'});
	} else if (command === 'help') {
		resultItems.push({type: 'welcome'});
	} else if (command.startsWith('ld')) {
		if (command.startsWith('ld ')) {
			const gameName = command.replace('ld ', '');
			const game = Object.keys(games).find(key => key === gameName);

			if (game !== undefined) {
				resultItems.push({type: 'component', game});
			} else {
				resultItems.push({type: 'error', text: gameNotFoundMessage(command)});
			}
		} else {
			resultItems.push({type: 'error', text: NO_SPACE_AFTER_LD_MESSAGE});
		}
	} else {
		resultItems.push({type: 'error', text: commandNotFoundMessage(command)});
	}

	return resultItems;
}
