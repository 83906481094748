import { TerminalPre, TerminalColor } from './styles';

const Welcome = props => {
  return <div>
      {/*<TerminalColor color="red">♥</TerminalColor>*/}
      ============================<br></br>
      <br></br>
      welcome to studio ld &lt;3<br></br>
      <TerminalColor color="#ff0066"> &lt;games.art.code&gt;</TerminalColor><br></br>
      <br></br>
      <TerminalPre> △  △     _______</TerminalPre>
      <TerminalPre> ◉  ◉    |       |</TerminalPre>
      <TerminalPre> &gt;•&lt;     | ld <TerminalColor color="red">&lt;3</TerminalColor> |</TerminalPre>
      <TerminalPre> /״ \    |_______| ࿐ </TerminalPre>
      <TerminalPre> \__/◠◡    |  |෴෴    ༄</TerminalPre>
      <br></br>
      ============================<br></br>
      {!props.isMobile ? 
        <div>
          use the command line at the bottom of the screen to browse the site, the following commands are avalible:<br></br>
          ◘ ls <TerminalColor color="#737373">(for the list of our projects)</TerminalColor><br></br>
          ◘ ld [project name] <TerminalColor color="#737373">(to load a project)</TerminalColor><br></br>
          ◘ about <TerminalColor color="#737373">(some info about the studio)</TerminalColor><br></br>
        </div>
       :""}
      <TerminalPre> </TerminalPre>
    </div>;
};

export default Welcome;
