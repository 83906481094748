import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: #ffec27;
`;

export const Ananas = props => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>ANANAS</p></GameHeader>
			<p>A <a href="https://www.lexaloffle.com/pico-8.php" target="blank">pico-8</a> game where you follow the ancient ways of mother nature from the roof of your urban apartment.</p>
			<ImageCarousel width="333" height="333" images={['images/ananas/moosla.png', 'images/ananas/bigAnanas.png', 'images/ananas/picoMusic.png', 'images/ananas/urban.png', 'images/ananas/graphicSheet.png']}></ImageCarousel>
			<p>
				▞ credits:<br></br>
				<a href="http://o--0oo0--o.com/" target="blank">rona manor</a> / game design, art, music+sfx, code <br></br>
				<a href="https://idankimel.org/" target="blank">idan kimel</a> / game design, code<br></br>
				opening theme melody by yaeli greenblatt <br></br>
			</p>
			<p>made for the <a href="https://itch.io/jam/jamuslala" target="blank">jamuslala</a> by <a href="https://www.facebook.com/makegamesjlm/" target="blank">make games jlm</a> + <a href="https://muslala.org/en/" target="blank">muslala</a></p>
			<p>play/download ananas <a href="https://colorful-courier.itch.io/ananas" target="blank">⟶ here ⟵</a></p>
		</DelayedLoader>
	</GameDiv>;
};
