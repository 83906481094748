import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: #c070c8;
`;

export const Station = (props) => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>STATION AO8 ***on hold, in early developmet***</p></GameHeader>
			<p>In a far away station, waiting for a beacon from the void. A transmission from an unknown source to a nameless destination.</p>
			<p>A game first created for the <a href="https://globalgamejam.org/2018/games/stati0n-ao88oa" target="blank">global game jam</a> and which later evolved into one of the studio’s projects. </p>
			<p>Station ao8 invites the player to a digital-analog journey based on different times of the day and night, and the spacial qualities they bring.</p>
			<ImageCarousel width="320" height="354" images={['/images/ao8/ao8Title.png', '/images/ao8/ao8Ladder.png', '/images/ao8/ao8Garden.png', '/images/ao8/ao8Room.png', '/images/ao8/ao8tools.png']}></ImageCarousel>
			<p>
				▞ credits:<br></br>
				<a href='http://o--0oo0--o.com/' target='blank'>rona manor</a> / game design, art, music+sfx<br></br>
				<a href='https://idankimel.org/' target='blank'>idan kimel</a> / game design, code<br></br>
			</p>
		</DelayedLoader>
	</GameDiv>;
};