import styled from 'styled-components';

export const TerminalColor = styled.span`
	color: ${props => props.color};
`;

export const TerminalPre = styled.pre`
	color: ${props => props.color};
	line-height: 24px;
	margin: 0;
	padding: 0;
`;

export const GameDiv = styled.div`
	padding-top: 10px;
	padding-bottom: 5px;
	line-height: 24px;
	max-width: 750px;
`;