import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: #00e436;
`;

export const JNB = props => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>JUMP N' BUMP ***in advanced developmet***</p></GameHeader>
			<p>A <a href='https://www.lexaloffle.com/pico-8.php' target='blank'>pico-8</a> d-make for the <a href="https://en.wikipedia.org/wiki/Jump_%27n_Bump" target="blank">90's dos game by Brainchild Design</a>.</p>
			<p>Other than tributing a 90's dos game, this d-make comes to celebrate the rich and evolving pico-8 community and offers a multi-player arcade game. In addition to the original game, the d-make presents a teams mode, 8 player(!), and a pico-8 take on the graphics and music.</p>
			<p>Like in every pico-8 game, the code is open to view and the game invites the community to create levels of their own. </p>
			<ImageCarousel width="333" height="333" images={['/images/jnb/jnbTitle.png', '/images/jnb/jnbTiles2.png', '/images/jnb/jnbMap.png']}></ImageCarousel>
			<p>
				▞ credits:<br></br>
				<a href='http://o--0oo0--o.com/' target='blank'>rona manor</a> / game design, art, music+sfx, code<br></br>
				<a href='https://idankimel.org/' target='blank'>idan kimel</a> / game design, code<br></br>
			</p>
		</DelayedLoader>
	</GameDiv>;
};