import styled from 'styled-components';
import { TerminalPre, TerminalColor } from '../styles';
import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';

import { useState, useEffect } from 'react';

const GameHeader = styled.p`
	color: #00e436;
`;
const SomeColor = styled.span`
	color: #00e436;
`;
const OffTitleColor = styled.span`
	color: #3aceea;
`;
const MailColor = styled.p`
	color: #ff8ded;
`;

export const About = props => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>LD &lt;3 STUDIO</p></GameHeader>
			<p><OffTitleColor>ld &lt;3 is a jerusalem based studio for games, art and code.</OffTitleColor></p>
			<p><SomeColor>O</SomeColor>ur projects examine the border between art and games, approaching the world of gaming and its elements as materials for artistic expression.</p>
			<p><SomeColor>W</SomeColor>e love digital cultures that are based on collaboration, open source, minimal use of digital resources, and values from old hardware.</p>
			<p><SomeColor>W</SomeColor>e are looking forward to a digital world that celebrates countless meanings within tiny storage spaces, low resolution screens, and humble cpu's. A digital culture that evolves without constantly discarding the old.</p>
			<p><SomeColor>M</SomeColor>ost of our projects incorporate old game consoles while searching for  contemporary interpretations of their unique values. Many beautiful elements were lost in the process of technological progress, we wish to rediscover and reuse them. </p>
			<p><SomeColor>W</SomeColor>e see games as a form of storytelling, a way to imagine, communicate and build spiritual ideas and feelings.</p>
			<p>Join us.</p>
			<p>
				▞ core team:<br></br>
				<a href="https://idankimel.org/" target="blank">idan kimel</a><br></br>
				<a href="http://o--0oo0--o.com/" target="blank">rona manor</a><br></br><br></br>
				<MailColor>📨 ld.heart.studio[at]gmail.com</MailColor>
			</p>
		</DelayedLoader>
	</GameDiv>;
};

