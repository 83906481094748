import Terminal from './components/Terminal';
import './App.css';

function App() {
	return (
		<Terminal/>
	);
}

export default App;
