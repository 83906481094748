import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { TerminalPre } from './styles';

import games from './games/games';
import { parseCommand } from '../parser/parser';

import Loading from './Loading.js';
import Welcome from './Welcome.js';
import GamesList from './GamesList.js';
import MobileButton from './MobileButton.js';

const Background = styled.div`
  color: white;
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding-top: 10px;
  position: relative;
`;

const FloatingDiv = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const CaretInput = styled.input`
  line-height: 21px;
  width: 100%;
  background-color: blue;
  border: none;
  color: white;
  outline: none;
  cursor: default;
  padding: 0;
  margin: 0;
`;

/*const MobileButton = styled.button`
  background-color: blue;
`;*/

const HiddenCaretInput = styled(CaretInput)`
  visibility: hidden;
`;

const ErrorMessage = styled(TerminalPre)`
  margin: 0;
  padding: 0;
  color:#ff5454;
`;

// From: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent) || 
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgent.substr(0,4));
};

function Terminal() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasEnteredCommand, setEnteredCommand] = useState(false);

  const scrollBottomRef = useRef(null);

  const autoScroll = () => {
    scrollBottomRef.current?.scrollIntoView();
  }

  const isMobile = isMobileOrTablet();

  const createItem = itemDef => {
    if (itemDef.type === 'command') {
      return <TerminalPre>{itemDef.text}</TerminalPre>;
    } else if (itemDef.type === 'error') {
      return <ErrorMessage>{itemDef.text}</ErrorMessage>;
    } else if (itemDef.type === 'welcome') {
      return <Welcome isMobile={isMobile}/>;
    } else if (itemDef.type === 'games-list') {
      return <GamesList/>;
    } else if (itemDef.type === 'component') {
      setIsLoading(true);
      const GameComponent = games[itemDef.game];
      return <GameComponent onNewLoad={autoScroll} onLoadingDone={() => setIsLoading(false)}/>;
    }
  };

  const [items, setItems] = useState(parseCommand('help', false).map(itemDef => createItem(itemDef)));
  const [command, setCommand] = useState('');

  useEffect(() => {
    if (hasEnteredCommand) {
      autoScroll();
    }
  });

  const sendCommand = command => {
    setEnteredCommand(true);
    setCommand('');
    const newItems = parseCommand(command).map(itemDef => createItem(itemDef));

    setItems([...items, ...newItems]);
  };

  return (
    <Background>
      <div>
        { items }
        <div ref={scrollBottomRef} />
        <HiddenCaretInput/>
      </div>
      {isLoading ? <Loading/> : ""}
      
      <FloatingDiv>
        {(!isLoading && !isMobile) ? <CaretInput maxLength="64" autoFocus onBlur={e => {setTimeout(() => e.target.focus(), 1)}} 
          onChange={e => setCommand(e.target.value)} 
          onKeyDown={e => (e.key === 'Enter') ? sendCommand(command) : null} 
          value={command} /> : ""}
      </FloatingDiv>

      {(!isLoading && isMobile) ? <MobileButton onClick={sendCommand}></MobileButton> :""}
      
    </Background>
  );
}

export default Terminal;
