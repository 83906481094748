import styled from 'styled-components';

import { GameDiv } from '../styles';
import DelayedLoader from './DelayedLoader';
import ImageCarousel from './ImageCarousel';

const GameHeader = styled.p`
	color: #3fbbfc;
`;

export const Kite = props => {
	return  <GameDiv>
		<DelayedLoader onNewLoad={props.onNewLoad} onLoadingDone={props.onLoadingDone}>
			<GameHeader>{'//-------------//'}<p>Kite</p></GameHeader>
			<p>fly slowly in a jerusalem corner of the sky.</p>
			<p>A meditative site-specific game, drifting through wind, paper and gameboy sounds. Created in collaboration with `Mekudeshet` for the Scherover Promenade in Jerusalem, a unique location connecting the east and west of the city.</p>
			<p>the game is available for mobile devices: <br></br><a href='https://play.google.com/store/apps/details?id=org.ldheart' target='blank'>android</a> / <a href='https://apps.apple.com/app/id1603041012' target='blank'>iphone</a></p>
			<ImageCarousel width="333" height="333" images={['/images/kite/kite1.png', '/images/kite/kite2.png', '/images/kite/kite3.png', '/images/kite/kite4.png']}></ImageCarousel>
			<p>
				▞ credits:<br></br>
				Preduced for <a href='https://en.mekudeshet.com/landing-home-page/' target='blank'>Mekudeshet</a>, Jerusalem, 2022<br></br>
				<a href='http://o--0oo0--o.com/' target='blank'>rona manor</a> / game design, art, music+sfx<br></br>
				<a href='https://idankimel.org/' target='blank'>idan kimel</a> / game design, code<br></br>
				yeali greenbaltt for the concept 💜
			</p>
		</DelayedLoader>
	</GameDiv>;
};