import { About } from './About';
import { JNB } from './JNB';
import { Ananas } from './Ananas';
import { Shi__ra } from './Shi__ra';
import { Station } from './Station';
import { ShapeGarden } from './ShapeGarden';
import { Kite } from './Kite';
 
const games = {
	about: About,
	ananas: Ananas,
	shi_ra: Shi__ra,
	jumpnbump: JNB,
	station_ao8: Station,
	gesusas_shape_garden: ShapeGarden,
	kite: Kite
}

export default games;